import React from 'react'
import { Route, Routes, useLocation } from 'react-router-dom';
import '../App.css';
import PublicNavbar from './PublicNavbar';
import PublicStandings from './PublicStandings';
import UpcomingWeek from './UpcomingWeek';
import PublicSchedule from './PublicSchedule';
import Contact from './Contact';
import TeamStandings from './TeamStandings';
import TeamSchedule from './TeamSchedule';
import PublicBoxScore from './PublicBoxScore';
import Player from './Player';

const Public = ({logo, teams, schedule, db}) => {
  return (
    <div>
        <PublicNavbar />
        <div className='logoin'><img className="logo" src={logo} alt="ICM logo"></img></div>
        <Routes>
            <Route path="/" element={
                <div className='homepage'>
                    <PublicStandings
                        tableName="Standings"
                        span="5"
                        teams={teams}
                    />
                    <UpcomingWeek tableName="Upcoming Week" span="5" schedule={schedule} />
                </div>
            }
            />
            <Route path='schedule' element={
                <PublicSchedule
                    tableName="Schedule"
                    span="5"
                    schedule={schedule}
                />
            }
            />
            <Route path='contact' element={
                <Contact />
            } 
            />
            <Route path='team/*' element={
                <div className='homepage'>
                    <TeamStandings
                        team={useLocation().pathname}
                        span="6"
                        db={db}
                    />
                    <TeamSchedule
                        tableName="Team Schedule"
                        span="5"
                        team={useLocation().pathname}
                        schedule={schedule}
                    />
                </div>
            }
            />
            <Route path='Box Score/*' element={
                <div className='Box-Scores'>
                    <PublicBoxScore
                        border="border-right"
                        span="5"
                        game={useLocation().pathname}
                        team="Home"
                        db={db}
                    />
                    <PublicBoxScore
                        border="border-left"
                        span="5"
                        game={useLocation().pathname}
                        team="Away"
                        db={db}
                    />
                </div>
            }
            />
            <Route path='player/*' element={
                <Player
                    player={useLocation().pathname}
                    schedule={schedule}
                    db={db}
                />
            }
            />
        </Routes>
    </div>
  )
}

export default Public