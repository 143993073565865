import React from 'react'
import { Link } from 'react-router-dom'

const ReadOnlyRow = ({team, counter, handleEditClick, handleDeleteClick}) => {
  return (
    <tr key={team.name}>
      <td>{++counter}</td>
      <td><Link to={"/admin/team/" + team.name} className="teamlink">{team.name}</Link></td>
      <td>{team.wins + team.losses}</td>
      <td>{team.wins}</td>
      <td>{team.losses}</td>
      <td>
        <button type="button" onClick={(event) => handleEditClick(event, team)}>Edit</button>
      </td>
    </tr>
  )
}

export default ReadOnlyRow