import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import React, { useState, useEffect } from 'react'

const PublicBoxScore = ({border, span, game, team, db}) => {

    const gameName = game.includes("admin") ? game.split("/")[3].replaceAll("%20", " ") : game.split("/")[2].replaceAll("%20", " ");
    const [teamName, setTeamName] = useState("");
    const [players, setPlayers] = useState([]);
    const [score, setScore] = useState(0);

    useEffect(() => {
        const getPlayers = async () => {
            const playerList = [];
            const gameSnapshot = await getDoc(doc(db, `Box Scores Summer 2024/${gameName}`))
            team === "Home" ? setScore(gameSnapshot.data()["Home Score"]) : setScore(gameSnapshot.data()["Away Score"]);
            const teamNameSnapshot = await getDocs(collection(db, "Box Scores Summer 2024", gameName, team));
            for (const d of teamNameSnapshot.docs) {
                setTeamName(d.data().Name);
                const querySnapshot = await getDocs(collection(db, "Box Scores Summer 2024", gameName, team, d.data().Name, "Players"))
                querySnapshot.forEach((d) => {
                    playerList.push(d.data());
                })
            }
            setPlayers(playerList)
        }
        getPlayers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className='table'>
            <table className={border + " content-table Box-Scores "}>
                <thead>
                    <tr>
                        <th colSpan={10}>Score: {score}</th>
                    </tr>
                    <tr>
                        <th colSpan={span}>{teamName}</th>
                    </tr>
                    <tr>
                        <th>Number</th>
                        <th>Player</th>
                        <th>Points</th>
                        <th>Rebounds</th>
                        <th>Assists</th>
                    </tr>
                </thead>
                <tbody>
                    {players.map((player) => (
                        <tr key={player.Number + player.Name}>
                            <td>{player.Number}</td>
                            <td>{player.Name}</td>
                            <td>{player.Points}</td>
                            <td>{player.Rebounds}</td>
                            <td>{player.Assists}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default PublicBoxScore