import React from 'react'

const Contact = () => {
  return (
    <div className='contact'>
        <p>Please contact Abdul Razack (240-848-8262) or Ali Mahdi (301-956-4079) for more information or email us at icmathletic@gmail.com</p>
        <p>Check us out on <a href='https://www.instagram.com/icmathletics/' target="_blank" rel="noreferrer" className='teamlink'>Instagram</a> to stay up to date on the latest news!</p>
    </div>
  )
}

export default Contact