import { getDocs, collection } from 'firebase/firestore';
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

const TeamStandings = ({span, team, db}) => {
    const teamName = team.includes("admin") ? team.split("/")[3].replaceAll("%20", " ") : team.split("/")[2].replaceAll("%20", " ");
    const [players, setPlayers] = useState([])
    useEffect(() => {
        const getPlayers = async () => {
            const playerList = [];
            const querySnapshot = await getDocs(collection(db, "Teams", teamName, "Players"));
            querySnapshot.forEach((d) => {
                playerList.push(d.data());
            })
            setPlayers(playerList)
        }
        getPlayers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className='table'>
            <table className="content-table">
                <thead>
                    <tr>
                        <th colSpan={span}>{teamName}</th>
                    </tr>
                    <tr>
                        <th>Number</th>
                        <th>Player</th>
                        <th>Games Played</th>
                        <th>Points</th>
                        <th>Rebounds</th>
                        <th>Assists</th>
                    </tr>
                </thead>
                <tbody>
                    {players.map((player) => (
                        <tr key={player.Number}>
                            <td>{player.Number}</td>
                            <td><Link to={!window.location.pathname.includes('admin') ? "/player/" + teamName + "/" + player.Name : "/admin/player/" + teamName + "/" + player.Name} className="teamlink">{player.Name}</Link></td>
                            <td>{player.GamesPlayed}</td>
                            <td>{player.GamesPlayed > 0 && player.Points > 0 ? (player.Points/player.GamesPlayed).toFixed(1) : 0}</td>
                            <td>{player.GamesPlayed > 0 && player.Rebounds > 0 ? (player.Rebounds/player.GamesPlayed).toFixed(1) : 0}</td>
                            <td>{player.GamesPlayed > 0 && player.Assists > 0 ? (player.Assists/player.GamesPlayed).toFixed(1) : 0}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default TeamStandings