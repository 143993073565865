import React from 'react'
import { Link } from 'react-router-dom';

const TeamSchedule = ({tableName, span, team, schedule}) => {

    console.log(team);
    const teamName = team.includes("admin") ? team.split("/")[3].replaceAll("%20", " ") : team.split("/")[2].replaceAll("%20", " ");
    const teamSched = [];
    schedule.forEach((game) => {
        if(game["Home Team"].includes(teamName) || game["Away Team"].includes(teamName)) {
            teamSched.push(game);
        }
    });

    if(teamSched.length === 0) teamSched.push({
        "Week": "",
        "Home Team": "",
        "Away Team": "No Upcoming Games",
        "Time": "",
        "Score": ""
    })

    return (
        <div className="table">
            <table className="content-table">
                <thead>
                    <tr>
                        <th colSpan={span}>{tableName}</th>
                    </tr>
                    <tr>
                        <th>Week</th>
                        <th>Home Team</th>
                        <th>Away Team</th>
                        <th>Time</th>
                        <th>Score</th>
                    </tr>
                </thead>
                <tbody>
                {teamSched.map((game) => (
                    <tr key={game.Week}>
                        <>
                        {game.Week.includes("BYE") ? (
                            <td>{game.Week.includes("x") ? game.Week.replaceAll("x", "") : game.Week}</td>
                        ) : (
                            <td><Link to={!window.location.pathname.includes('admin') ? "/Box Score/" + game.Week : "/admin/Box Score/" + game.Week} className="teamlink">{game.Week.includes("x") ? game.Week.replaceAll("x", "") : game.Week}</Link></td>
                        )}
                        </>
                        <td>{game["Home Team"]}</td>
                        <td>{game["Away Team"]}</td>
                        <td>{game.Time}</td>
                        <td>{game.Score}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
  )
}

export default TeamSchedule