import React from 'react'
import { Link } from 'react-router-dom'

const ReadOnlySchedule = ({game, handleEditClick, span}) => {
    return (
        <>
            {game.Week.includes("Week") && !game.Week.includes("Game") && !game["Week"].includes("BYE") ? (
                <tr className='week'>
                    <td>{game.Week.includes("x") ? game.Week.replaceAll("x", "") : game.Week}</td>
                    <td colSpan={span-1}>{game["Home Team"]}</td>
                </tr>
            ) : (game["Week"].includes("BYE") && game["Home Team"].length > 0 ? (
                <tr>
                    <td>{game.Week.split(/Week x*\d+ x*/)[1]}</td>
                    <td colSpan={2}>{game["Home Team"]}</td>
                    <td colSpan={2}>{game["Away Team"]}</td>
                    <td></td>
                </tr>
            ) : (game["Week"].includes("BYE") && game["Home Team"].length == 0 ? (
                <tr>
                    <td colSpan={span}>{game.Week.split(/Week x*\d+ x*/)[1]}</td>
                </tr>
                ) : (
                <tr>
                    <td><Link to={"/admin/Box Score/" + game.Week} className='teamlink'>{game.Week.split(/Week x*\d+ /)[1]}</Link></td>
                    <td>{game["Home Team"]}</td>
                    <td>{game["Away Team"]}</td>
                    <td>{game.Time}</td>
                    <td>{game.Score}</td>
                    <td>
                        <button type="button" onClick={(event) => handleEditClick(event, game)}>Edit</button>
                    </td>
                </tr>
            )))}
        </>
    )
}

export default ReadOnlySchedule