import React, { Fragment, useEffect, useState } from 'react'
import EditableBoxScore from './EditableBoxScore';
import ReadOnlyBoxScore from './ReadOnlyBoxScore';
import { collection, getDocs, orderBy, query, setDoc, doc, deleteDoc, updateDoc, getDoc, where, increment } from "firebase/firestore";


const BoxScore = ({border, span, team, db, game}) => {

    const gameName = game.includes("admin") ? game.split("/")[3].replaceAll("%20", " ") : game.split("/")[2].replaceAll("%20", " ");
    const [editPlayer, setEditPlayer] = useState(null);
    const [editScore, setEditScore] = useState(false)

    const [editPlayerData, setEditPlayerData] = useState({
        Points: 0,
        Assists: 0,
        Rebounds: 0,
        GamePlayed: false
    });

    const [initialPlayerData, setInitialPlayerData] = useState({
        Points: 0,
        Assists: 0,
        Rebounds: 0,
        GamePlayed: false
    });

    const [teamName, setTeamName] = useState("");
    const [players, setPlayers] = useState([]);
    const [score, setScore] = useState(0);
    const [initialScore, setInitialScore] = useState(0);

    useEffect(() => {
        const getPlayers = async () => {
            const playerList = [];
            const gameSnapshot = await getDoc(doc(db, `Box Scores Summer 2024/${gameName}`))
            team === "Home" ? setScore(gameSnapshot.data()["Home Score"]) : setScore(gameSnapshot.data()["Away Score"]);
            team === "Home" ? setInitialScore(gameSnapshot.data()["Home Score"]) : setInitialScore(gameSnapshot.data()["Away Score"]);
            const teamNameSnapshot = await getDocs(collection(db, "Box Scores Summer 2024", gameName, team));
            for (const d of teamNameSnapshot.docs) {
                setTeamName(d.data().Name);
                const querySnapshot = await getDocs(collection(db, "Box Scores Summer 2024", gameName, team, d.data().Name, "Players"))
                querySnapshot.forEach((d) => {
                    playerList.push(d.data());
                })
            }
            setPlayers(playerList)
        }
        getPlayers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleEditPlayerSubmit = async (event) => {
        event.preventDefault();

        const newTeam = [...players];
        const index = players.findIndex((player) => (player.Name) === editPlayer);
        console.log(editPlayerData);

        const playerRef = doc(db, "Box Scores Summer 2024", gameName, team, teamName, "Players", editPlayer);
        const playerAveragesRef = doc(db, "Teams", teamName, "Players", editPlayer)

        console.log("edit", editPlayerData.GamePlayed);
        console.log("init", initialPlayerData.GamePlayed);

        await updateDoc(playerRef, {
            Points: editPlayerData.Points,
            Rebounds: editPlayerData.Rebounds,
            Assists: editPlayerData.Assists,
            GamePlayed: editPlayerData.GamePlayed === 'true'
        });

        await updateDoc(playerAveragesRef, {
            Points: increment(editPlayerData.Points - initialPlayerData.Points),
            Rebounds: increment(editPlayerData.Rebounds - initialPlayerData.Rebounds),
            Assists: increment(editPlayerData.Assists - initialPlayerData.Assists),
            GamesPlayed: ((editPlayerData.GamePlayed && editPlayerData.GamePlayed !== 'false') && (!initialPlayerData.GamePlayed || initialPlayerData.GamePlayed === 'false')) ? increment(1) : ((!editPlayerData.GamePlayed || editPlayerData.GamePlayed === 'false')  && (initialPlayerData.GamePlayed && initialPlayerData.GamePlayed !== 'false') ? increment(-1) : increment(0))
        });

        const editedPlayer = {
            Name: newTeam[index].Name,
            Number: newTeam[index].Number,
            Points: editPlayerData.Points,
            Rebounds: editPlayerData.Rebounds,
            Assists: editPlayerData.Assists,
            GamePlayed: editPlayerData.GamePlayed
        }

        newTeam[index] = editedPlayer;
        setPlayers(newTeam);
        setEditPlayer(null);
    }

    const handleEditClick = (event, player) => {
        event.preventDefault();
        setEditPlayer(player.Name);

        const formValues = {
            Points: player.Points,
            Assists: player.Assists,
            Rebounds: player.Rebounds,
            GamePlayed: player.GamePlayed
        }
        setEditPlayerData(formValues);
        setInitialPlayerData(formValues);
        console.log(editPlayerData)
    }

    const handleEditScoreClick = (event) => {
        event.preventDefault();
        setEditScore(true);
    }

    const handleEditScoreChange = (event) => {
        event.preventDefault();
        const fieldValue = event.target.value;

        setScore(fieldValue)
    }

    const handleEditScoreSubmit = async (event) => {
        event.preventDefault();

        const scoreRef = doc(db, "Box Scores Summer 2024", gameName);
        if(team === "Home") {
            await updateDoc(scoreRef, {
                "Home Score": score
            })
        }
        else {
            await updateDoc(scoreRef, {
                "Away Score": score
            })
        }
        setInitialScore(score);
        setEditScore(false);
    }

    const handleCancelClick = () => {
        setEditPlayer(null);
        setScore(initialScore);
        setEditScore(false);
    }

    return (
        <div className='table'>
            <form onSubmit={handleEditPlayerSubmit}>
            <table className={border + " content-table Box-Scores "}>
                <thead>
                    <Fragment>
                        {editScore ? (
                            <tr>
                                <th colSpan={6}>
                                    <label>Score: </label>
                                    <input className='editScheduleInput'
                                        type='number'
                                        name='Score'
                                        required='required'
                                        value={score}
                                        onFocus={e => e.target.select()}
                                        onChange={handleEditScoreChange}
                                    />
                                </th>
                                <th>
                                    <button type='button' onClick={handleEditScoreSubmit}>Save</button>
                                    <button type='button' onClick={handleCancelClick}>Cancel</button>
                                </th>
                            </tr>
                        ) : (   
                            <tr>
                                <th colSpan={6}>Score: {score}</th>
                                <th>
                                    <button type="button" onClick={(event) => handleEditScoreClick(event)}>Edit</button>
                                </th>
                            </tr>
                        )}
                    </Fragment>
                    <tr>
                        <th colSpan={span+1}>{teamName}</th>
                    </tr>
                    <tr>
                        <th>Number</th>
                        <th>Player</th>
                        <th>Points</th>
                        <th>Rebounds</th>
                        <th>Assists</th>
                        <th>Game Played</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {players.map((player) => (
                        <Fragment>
                            {editPlayer === player.Name ? (
                                <EditableBoxScore
                                    player={player}
                                    editPlayerData={editPlayerData}
                                    setEditPlayerData={setEditPlayerData}
                                    handleCancelClick={handleCancelClick}
                                />
                            ) : (
                                <ReadOnlyBoxScore
                                    player={player}
                                    handleEditClick={handleEditClick}
                                />
                            )}
                        </Fragment>
                    ))}
                </tbody>
            </table>
            </form>
        </div>
    )
}

export default BoxScore