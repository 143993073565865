import React, { useEffect } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Navbar from './Navbar'
import Standings from './Standings.jsx'
import '../App.css';
import SubmitForm from './SubmitForm.jsx';
import Schedule from './Schedule.jsx';
import Contact from './Contact.jsx';
import UpcomingWeek from './UpcomingWeek.jsx';
import ProtectedRoute from './ProtectedRoute';
import { UserAuth } from '../AuthContext';
import TeamStandings from './TeamStandings';
import TeamSchedule from './TeamSchedule';
import BoxScore from './BoxScore';
import Player from './Player';



const Admin = ({logo, setTeams, teams, editTeam, setEditTeam, editFormData, setEditFormData, submitFormData,
    setSubmitFormData, schedule, setSchedule, editSchedule, setEditSchedule, editScoreData, setEditScoreData, auth, db}) => {
    
    const { user, logout } = UserAuth();
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
          await logout();
          navigate('/login');
          console.log('You are logged out')
        } catch (e) {
          console.log(e.message);
        }
    };

    return (
        <div>
        <Navbar />
        <div className='logoin'>
            <img className="logo" src={logo} alt="ICM logo"></img>
            <button onClick={handleLogout} className='border px-6 py-2 my-4'>Logout</button>
        </div>
        <Routes>
                <Route path='/' element={
                <>
                    <div className='homepage'>
                        <Standings
                            tableName="Standings"
                            span="6"
                            setTeams={setTeams}
                            teams={teams}
                            editTeam={editTeam}
                            setEditTeam={setEditTeam}
                            editFormData={editFormData}
                            setEditFormData={setEditFormData}
                            db={db}
                        />
                        <UpcomingWeek tableName="Upcoming Week" span="5" schedule={schedule} />
                    </div>
                    <SubmitForm submitFormData={submitFormData} setSubmitFormData={setSubmitFormData} setTeams={setTeams} teams={teams} db={db}/>
                </>
                }
                />
                <Route path='schedule' element={
                    <Schedule 
                        tableName="Schedule"
                        span="6"
                        schedule={schedule}
                        setSchedule={setSchedule}
                        editSchedule={editSchedule}
                        setEditSchedule={setEditSchedule}
                        editScoreData={editScoreData}
                        setEditScoreData={setEditScoreData}
                        db={db}
                    />
                } 
                />
                <Route path='contact' element={
                    <Contact />
                } 
                />
                <Route path='team/*' element={
                    <div className='homepage'>
                        <TeamStandings
                            team={useLocation().pathname}
                            span="6"
                            db={db}
                        />
                        <TeamSchedule
                            tableName="Team Schedule"
                            span="5"
                            team={useLocation().pathname}
                            schedule={schedule}
                        />
                    </div>
                }
                />
                <Route path='player/*' element={
                    <Player
                        player={useLocation().pathname}
                        schedule={schedule}
                        db={db}
                    />
                }
                />
                 <Route path='Box Score/*' element={
                    <div className='Box-Scores'>
                        <BoxScore
                            border="border-right"
                            span="5"
                            game={useLocation().pathname}
                            team="Home"
                            db={db}
                        />
                        <BoxScore
                            border="border-left"
                            span="5"
                            game={useLocation().pathname}
                            team="Away"
                            db={db}
                        />
                    </div>
                }
                />
        </Routes>
        </div>
        // {/* <button onClick={signOut}>Sign out</button> */}
        
        
        // )}
        // </Authenticator>
    )
}

export default Admin