import React from 'react'

const EditableSchedule = ({game, editScoreData, setEditScoreData, handleCancelClick}) => {

    const handleEditScoreChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.getAttribute("name");
        const fieldValue = event.target.value;

        const newScoreData = {...editScoreData};
        newScoreData[fieldName] = fieldValue;
        setEditScoreData(newScoreData);
    }

    return (
        <tr>
            <td></td>
            <td>{game["Home Team"]}</td>
            <td>{game["Away Team"]}</td>
            <td>{game.Time}</td>
            <td>
            <input className='editScheduleInput'
                    type='text'
                    name='Score'
                    required='required'
                    placeholder='Score'
                    value={editScoreData.Score}
                    onChange={handleEditScoreChange}
                />
            </td>
            <td>
                <button type='submit'>Save</button>
                <button type='button' onClick={handleCancelClick}>Cancel</button>
            </td>
        </tr>
    )
}

export default EditableSchedule