import { Link } from 'react-router-dom'
import { useState } from 'react'

const Navbar = () => {

    const [showNavbar, setShowNavbar] = useState(false);

    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar);
    }

    const Hamburger = () => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="52"
          height="24"
          viewBox="0 0 52 24"
        >
          <g id="Group_9" data-name="Group 9" transform="translate(-294 -47)">
            <rect
              id="Rectangle_3"
              data-name="Rectangle 3"
              width="42"
              height="4"
              rx="2"
              transform="translate(304 47)"
              fill="#ffffff"
            />
            <rect
              id="Rectangle_5"
              data-name="Rectangle 5"
              width="42"
              height="4"
              rx="2"
              transform="translate(304 67)"
              fill="#ffffff"
            />
            <rect
              id="Rectangle_4"
              data-name="Rectangle 4"
              width="52"
              height="4"
              rx="2"
              transform="translate(294 57)"
              fill="#ffffff"
            />
          </g>
        </svg>
      );
      
    
    return (
        <nav className='navbar'>
            <div className='container'>
                <div></div>
                {/* <img className="logo" src={logo} alt="ICM logo"></img> */}
                <div className={`nav-elements  ${showNavbar && 'active'}`} onClick={showNavbar ? handleShowNavbar : undefined}>
                    <ul>
                        <li><Link to="/admin/">Home</Link></li>
                        <li><Link to='/admin/schedule'>Schedule</Link></li>
                        <li><Link to='/admin/contact'>Contact</Link></li>
                    </ul>
                </div>
                <div></div>
                <div className="menu-icon" onClick={handleShowNavbar}>
                    <Hamburger />
                </div>
            </div>
        </nav>
    )
}

export default Navbar