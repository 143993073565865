import React from 'react';
import { nanoid } from 'nanoid';
import { addDoc, collection } from "firebase/firestore";

const SubmitForm = ({submitFormData, setSubmitFormData, setTeams, teams, db}) => {
    
    const handleSubmitFormChange = (event) => {
        event.preventDefault();
  
        const teamName = event.target.value;
        const newFormData = {...submitFormData};
        newFormData['name'] = teamName;
  
        setSubmitFormData(newFormData);
    }
  
    const handleSubmitFormSubmit = async (event) => {
        event.preventDefault();

        const docRef = await addDoc(collection(db, "Teams"), {
            name: submitFormData.name,
            wins: 0,
            losses: 0
        });
  
        const newTeam = {
            id:nanoid(),
            rank:1,
            name:submitFormData.name,
            gp:0,
            wins:0,
            losses:0
        }
  
        const newTeams = [...teams, newTeam];
        setTeams(newTeams);
        document.getElementsByName('team')[0].value = "";
    }

    return (
        <div className='submitForm'>
            <h2>Add a Team</h2>
            <form onSubmit={handleSubmitFormSubmit}>
                <input
                    type='text'
                    name='team'
                    required='required'
                    placeholder='Enter Team Name'
                    onChange={handleSubmitFormChange}
                />
                <button type='submit'>Submit</button>
            </form>
        </div>
    )
}

export default SubmitForm