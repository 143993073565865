import React from "react";
import { Link } from 'react-router-dom'

const PublicStandings = ({tableName, span, teams}) => {
    const teams2 = [...teams].sort((a, b) => {
        if (a.wins === b.wins) {
            return a.losses - b.losses;
        }
        else return b.wins - a.wins;
    });
    
    return (
    <div className="table">
            <table className="content-table">
                <thead>
                    <tr>
                        <th colSpan={span}>{tableName}</th>
                    </tr>
                    <tr>
                        <th>Rank</th>
                        <th>Team</th>
                        <th>Games Played</th>
                        <th>Wins</th>
                        <th>Losses</th>
                    </tr>
                </thead>
                <tbody>
                    {teams2.map((team, counter) => (
                        <tr key={team.name}>
                            <td>{++counter}</td>
                            <td><Link to={"/team/" + team.name} className="teamlink">{team.name}</Link></td>
                            <td>{team.wins + team.losses}</td>
                            <td>{team.wins}</td>
                            <td>{team.losses}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
    </div>
    )
}


export default PublicStandings;