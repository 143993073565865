import React, { useState } from 'react'

const EditableBoxScore = ({player, editPlayerData, setEditPlayerData, handleCancelClick }) => {

    const [selected, setSelected] = useState(editPlayerData.GamePlayed.toString());

    const handleEditPlayerChange = (event) => {
        if(event.target.type !== 'radio') event.preventDefault();

        const fieldName = event.target.getAttribute("name");
        const fieldValue = event.target.value;

        const newScoreData = {...editPlayerData};
        newScoreData[fieldName] = fieldValue;
        setEditPlayerData(newScoreData);
        if(event.target.type === 'radio') setSelected(event.target.value);
    }

    // if (d.data().Week.includes("Game")) {
    //     setDoc(doc(db, `Box Scores Summer 2024/${d.data().Week}/Home/${d.data()["Home Team"]}/Players`, "Player 1"), {
    //       Name: "Player 1",
    //       Number: 0,
    //       Points: 10,
    //       Rebounds: 5,
    //       Assists: 5
    //     })
    // }

    return (
        <tr>
            <td>{player.Number}</td>
            <td>{player.Name}</td>
            <td>
                <input className='editScheduleInput'
                    type='text'
                    name='Points'
                    required='required'
                    placeholder='Points'
                    value={editPlayerData.Points}
                    onChange={handleEditPlayerChange}
                />
            </td>
            <td>
                <input className='editScheduleInput'
                    type='text'
                    name='Rebounds'
                    required='required'
                    placeholder='Rebounds'
                    value={editPlayerData.Rebounds}
                    onChange={handleEditPlayerChange}
                />
            </td>
            <td>
                <input className='editScheduleInput'
                    type='text'
                    name='Assists'
                    required='required'
                    placeholder='Assists'
                    value={editPlayerData.Assists}
                    onChange={handleEditPlayerChange}
                />
            </td>
            <td>
                <label style={{display: "block"}}>
                    <input 
                        type="radio" 
                        name="GamePlayed" 
                        value="true" 
                        required
                        checked={selected === 'true'}
                        onChange={handleEditPlayerChange}
                        /> 
                        Yes
                </label>
                <label style={{display: "block"}}>
                    <input 
                        type="radio" 
                        name="GamePlayed" 
                        value="false"
                        checked={selected === 'false'}
                        onChange={handleEditPlayerChange}
                        /> 
                        No
                </label>
            </td>
            <td>
                <button type='submit'>Save</button>
                <button type='button' onClick={handleCancelClick}>Cancel</button>
            </td>
        </tr>
    )
}

export default EditableBoxScore