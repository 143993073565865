import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

const PublicSchedule = ({tableName, span, schedule}) => {

    return (
        <div className="table center">
            <table className="content-table">
                <thead>
                    <tr>
                        <th colSpan={span}>{tableName}</th>
                    </tr>
                    <tr>
                        <th>Week</th>
                        <th>Home Team</th>
                        <th>Away Team</th>
                        <th>Time</th>
                        <th>Score</th>
                    </tr>
                </thead>
                <tbody>
                {schedule.map((game) => (
                     <Fragment key={game["Home Team"] + game["Away Team"] + game["Score"] +game["Time"]}>
                     {game.Week.includes("Week") && !game.Week.includes("Game") && !game["Week"].includes("BYE") ? (
                         <tr className='week'>
                             <td>{game.Week.includes("x") ? game.Week.replaceAll("x", "") : game.Week}</td>
                             <td colSpan={span-1}>{game["Home Team"]}</td>
                         </tr>
                     ) : (game["Week"].includes("BYE") && game["Home Team"].length > 0 ? (
                        <tr>
                            <td>{game.Week.split(/Week x*\d+ x*/)[1]}</td>
                            <td colSpan={2}>{game["Home Team"]}</td>
                            <td>{game["Away Team"]}</td>
                            <td></td>
                        </tr>
                    ) : (game["Week"].includes("BYE") && game["Home Team"].length == 0 ? (
                        <tr>
                            <td colSpan={span}>{game.Week.split(/Week x*\d+ x*/)[1]}</td>
                        </tr>
                        ) : (
                         <tr>
                             <td><Link to={"/Box Score/" + game.Week} className="teamlink">{game.Week.split(/Week x*\d+ /)[1]}</Link></td>
                             <td>{game["Home Team"]}</td>
                             <td>{game["Away Team"]}</td>
                             <td>{game.Time}</td>
                             <td>{game.Score}</td>
                         </tr>
                     )))}
                 </Fragment>
                ))}
                </tbody>
            </table>
        </div>
    )
}

export default PublicSchedule