import { doc, getDoc } from 'firebase/firestore';
import { React, useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

const Player = ({player, schedule, db}) => {
    const teamName = player.includes("admin") ? player.split("/")[3].replaceAll("%20", " ") : player.split("/")[2].replaceAll("%20", " ");
    const playerName = player.includes("admin") ? decodeURIComponent(player.split("/")[4].replaceAll("%20", " ")) : decodeURIComponent(player.split("/")[3].replaceAll("%20", " "));

    const [playerStats, setPlayerStats] = useState([]);

    useEffect(() => {
        const getStats = async () => {
            const statList = [];
            for (const game of schedule) {
                if(game["Home Team"].includes(teamName) && !game.Week.includes("BYE")) {
                    const player = await getDoc(doc(db, "Box Scores Summer 2024", game.Week, "Home", game["Home Team"], "Players", playerName));
                    const obj = {
                        Week: game.Week,
                        Opponent: game["Away Team"],
                        Points: player.data().Points,
                        Rebounds: player.data().Rebounds,
                        Assists: player.data().Assists
                    }
                    statList.push(obj);
                }
                else if(game["Away Team"].includes(teamName) && !game.Week.includes("BYE")) {
                    const player = await getDoc(doc(db, "Box Scores Summer 2024", game.Week, "Away", game["Away Team"], "Players", playerName));
                    const obj = {
                        Week: game.Week,
                        Opponent: game["Home Team"],
                        Points: player.data().Points,
                        Rebounds: player.data().Rebounds,
                        Assists: player.data().Assists
                    }
                    statList.push(obj);
                }
            }
            setPlayerStats(statList);
        }
        if(playerStats.length === 0) getStats();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playerStats]);

    return (
        <div className="table">
            <table className="content-table">
                <thead>
                    <tr>
                        <th colSpan={5}>{playerName}</th>
                    </tr>
                    <tr>
                        <th>Week</th>
                        <th>Opponent</th>
                        <th>Points</th>
                        <th>Rebounds</th>
                        <th>Assists</th>
                    </tr>
                </thead>
                <tbody>
                {playerStats.map((game) => (
                    <tr key={game.Week}>
                        <td><Link to={!window.location.pathname.includes('admin') ? "/Box Score/" + game.Week : "/admin/Box Score/" + game.Week} className="teamlink">{game.Week.includes("x") ? game.Week.replaceAll("x", "") : game.Week}</Link></td>
                        <td>{game.Opponent}</td>
                        <td>{game.Points}</td>
                        <td>{game.Rebounds}</td>
                        <td>{game.Assists}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    )
}

export default Player