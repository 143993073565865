import React from 'react'

const ReadOnlyBoxScore = ({player, handleEditClick}) => {
    return (
        <tr>
            <td>{player.Number}</td>
            <td>{player.Name}</td>
            <td>{player.Points}</td>
            <td>{player.Rebounds}</td>
            <td>{player.Assists}</td>
            <th>{player.GamePlayed && player.GamePlayed !== 'false' ? "True" : "False"}</th>
            <td>
                <button type="button" onClick={(event) => handleEditClick(event, player)}>Edit</button>
            </td>
        </tr>
    )
}

export default ReadOnlyBoxScore