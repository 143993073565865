import React from 'react'

const EditableRow = ({team, editFormData, setEditFormData, handleCancelClick,}) => {

    const handleEditFormChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.getAttribute("name");
        const fieldValue = event.target.value;

        const newFormData = {...editFormData};
        newFormData[fieldName] = fieldValue;
        setEditFormData(newFormData);
    }

    return (
        <tr>
            <td></td>
            <td>{team.name}</td>
            <td>{team.wins + team.losses}</td>
            <td>
            <input  className='editStandingInput'
                    type='text'
                    name='wins'
                    required='required'
                    placeholder='Wins'
                    value={editFormData.wins}
                    onChange={handleEditFormChange}
                />
            </td>
            <td>
            <input  className='editStandingInput'
                    type='text'
                    name='losses'
                    required='required'
                    placeholder='Losses'
                    value={editFormData.losses}
                    onChange={handleEditFormChange}
                />
            </td>
            <td>
                <button type='submit'>Save</button>
                <button type='button' onClick={handleCancelClick}>Cancel</button>
            </td>
        </tr>
    )
}

export default EditableRow