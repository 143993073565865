import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import logo from "./icomd-logo.png";
import Admin from './components/Admin.jsx';
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from  'firebase/auth';
import { getFirestore, collection, getDocs, orderBy, query, setDoc, doc, deleteDoc, updateDoc, getDoc } from "firebase/firestore";
import Public from './components/Public';
import ProtectedRoute from './components/ProtectedRoute';
import Login from './components/Login.jsx';
import { AuthContextProvider } from './AuthContext';
import TagManager from 'react-gtm-module';
// import raw from './2024 ICM SUMMER LEAGUE TEAM ROSTER-COLOR FINAL.xlsx - MD SHOOTERS (LIGHT BLUE-WHITE).csv';


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyCd9iqh4M05oOhucMW04770M7g3ZkMxhGo",
  authDomain: "icmbasketballleague.firebaseapp.com",
  projectId: "icmbasketballleague",
  storageBucket: "icmbasketballleague.appspot.com",
  messagingSenderId: "588003164244",
  appId: "1:588003164244:web:a5a914b53dd316fdb8edc1",
  measurementId: "G-CK1NW9YSQK"
};

const tagManagerArgs = {
  gtmId: 'G-CK1NW9YSQK'
}
TagManager.initialize(tagManagerArgs)


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const querySnapshot = await getDocs(collection(db, "Teams"));
const scheduleSnapshot = await getDocs(query(collection(db, "Schedule")));

// Read from a CSV file and create doc
// fetch(raw)
//  .then(r => r.text())
//  .then(async text => {
//   const rows = text.split(/\r?\n/)
//   await setDoc(doc(db, "Teams", "MD Shooters"), {
//     name: "MD Shooters",
//     wins: 0,
//     losses: 0
//   });
//   for (let i = 0; i < rows.length; i++) {
//     const columns = rows[i].split(",")
//     console.log(columns[0])
//     if (!columns[0].includes("FULL") && columns[0].length > 1) {
//       await setDoc(doc(db, "Teams", "MD Shooters", "Players", columns[0]), {
//           Assists: 0,
//           GamesPlayed: 0,
//           Name : columns[0],
//           Number: columns[2],
//           Points: 0,
//           Rebounds: 0
//         });
//         console.log(rows[i])
//     }
//   }
// });

// const fs = require('fs');

// fs.readFile('test.txt', 'utf8', (err, data) => {
//   if(err){
//     console.error(err);
//     return;
//   }
//   console. log(data);
// })
// await setDoc(doc(db, "Teams", "ICM United", "Players", "Oghuz Anwar"), {
//   Assists: 0,
//   GamesPlayed: 0,
//   Name : "Oghuz Anwar",
//   Number: 10,
//   Points: 0,
//   Rebounds: 0
// });

function App() {

  const [teams, setTeams] = useState([]);

  useEffect(() => {
    fetchTeams();
    fetchSchedule();
  }, [])

  const [schedule, setSchedule] = useState([]);

  const [submitFormData, setSubmitFormData] = useState({
    name: ''
  })

  const [editTeam, setEditTeam] = useState(null);
  const [editSchedule, setEditSchedule] = useState(null);

  const [editFormData, setEditFormData] = useState({
    wins: 0,
    losses: 0
  })

  const [editScoreData, setEditScoreData] = useState({
    Score: "TBD"
  })

  const fetchTeams = async () => {
    try {
      const teamList = [];
      querySnapshot.forEach(async (d) => {
        // This can be used to make changes to each player on a team
        // if (d.data().name === "Free Smoke") {
        //   playerSnapshot.forEach(async (p) => {
        //     await updateDoc(doc(db, "Teams", d.data().name, "Players", p.data().Name), {
        //       GamesPlayed: 0
        //     })
        //   })
        // }
        teamList.push(d.data());
        console.log(d.data());
      });
      setTeams(teamList);
    } catch (error) {
        console.log('error on fetching teams', error);
    }
  }

  const fetchSchedule = async () => {
    try {
      const sched = [];
      // Add single player to a box score
      // await setDoc(doc(db, "Box Scores Summer 2024", "Week x17 Game 1", "Home", "Free Smoke", "Players", "Zobair Karzai"), {
      //   Name: "Zobair Karzai",
      //   Number: 7,
      //   GamePlayed: false,
      //   Points: 0,
      //   Rebounds: 0,
      //   Assists: 0
      // })
      scheduleSnapshot.forEach(async (d) => {
        // This can be used to add/delete/update players from each box score
        // const homeSnapshot = await getDocs(collection(db, "Box Scores Summer 2024", d.data().Week, "Home", "Freak Time", "Players"));
        // const awaySnapshot = await getDocs(collection(db, "Box Scores Summer 2024", d.data().Week, "Away", "Freak Time", "Players"));
        
        // awaySnapshot.forEach(async (a) => {
        //   // await deleteDoc(doc(db, "Box Scores Summer 2024", d.data().Week, "Away", "Freak Time", "Players", "Abdul Basit Razack"));
        //   await setDoc(doc(db, "Box Scores Summer 2024", d.data().Week, "Away", "Freak Time", "Players", "Abdul Basit Razack"), {
        //     Name: "Abdul Basit Razack",
        //     Number: 17,
        //     GamePlayed: false,
        //     Points: 0,
        //     Rebounds: 0,
        //     Assists: 0
        //   })
        // })
        // homeSnapshot.forEach(async (h) => {
        //   // await deleteDoc(doc(db, "Box Scores Summer 2024", d.data().Week, "Home", "Freak Time", "Players", "Abdul Basit Razack"));
        //   await setDoc(doc(db, "Box Scores Summer 2024", d.data().Week, "Home", "Freak Time", "Players", "Abdul Basit Razack"), {
        //     Name: "Abdul Basit Razack",
        //     Number: 17,
        //     GamePlayed: false,
        //     Points: 0,
        //     Rebounds: 0,
        //     Assists: 0
        //   })
        // })

        // This can be used to change every box score
        // if (d.data().Week.includes("Game")) {
          // await setDoc(doc(db, "Box Scores Summer 2024", d.data().Week, "Away", d.data()["Away Team"]), {
          //   Name: d.data()["Away Team"]
          // })
          // await setDoc(doc(db, "Box Scores Summer 2024", d.data().Week, "Home", d.data()["Home Team"]), {
          //   Name: d.data()["Home Team"]
          // })
          // const boxSnapshot = await getDocs(collection(db, "Teams", d.data()["Away Team"], "Players"));
          // boxSnapshot.forEach(async (p) => {
            // await setDoc(doc(db, "Box Scores Summer 2024", d.data().Week, "Home", d.data()["Home Team"], "Players", p.data().Name), {
            //   Name: p.data()["Name"],
            //   Number: p.data().Number,
            //   Points: 0,
            //   Rebounds: 0,
            //   Assists: 0,
            //   GamePlayed: false
            // })

            // await deleteDoc(doc(db, "Box Scores Summer 2024", d.data().Week, "Away", d.data()["Away Team"], "Players", p.data().Name))

        //     await setDoc(doc(db, "Box Scores Summer 2024", d.data().Week, "Away", d.data()["Away Team"], "Players", p.data().Name), {
        //       Name: p.data()["Name"],
        //       Number: p.data().Number,
        //       Points: 0,
        //       Rebounds: 0,
        //       Assists: 0,
        //       GamePlayed: false
        //     })
        //   })
        // }
        sched.push(d.data());
      });
      setSchedule(sched);
    } catch (error) {
        console.log('error on fetching schedule', error);
    }
  }

  return (
    <>
     <AuthContextProvider>
    <Routes>
      <Route path='/login' element={
          <Login />
      }
      />
      <Route path='/*' element={
        <Public
          logo={logo}
          teams={teams}
          schedule={schedule}
          db={db}
        />
      }
      />
      <Route path='/admin/*' element={
        <ProtectedRoute>
        <Admin
          logo={logo}
          setTeams={setTeams}
          teams={teams}
          editTeam={editTeam}
          setEditTeam={setEditTeam}
          editFormData={editFormData}
          setEditFormData={setEditFormData}
          submitFormData={submitFormData}
          setSubmitFormData={setSubmitFormData}
          schedule={schedule}
          setSchedule={setSchedule}
          editSchedule={editSchedule}
          setEditSchedule={setEditSchedule}
          editScoreData={editScoreData}
          setEditScoreData={setEditScoreData}
          auth={auth}
          db={db}
        />
        </ProtectedRoute>
      }
      />
    </Routes>
    </AuthContextProvider>
    </>
  );
}

export default App;
