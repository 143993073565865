import React, { Fragment } from "react";
import ReadOnlyRow from "./ReadOnlyRow";
import EditableRow from "./EditableRow";
import { updateDoc, collection, where, query, getDocs, deleteDoc } from "firebase/firestore";

const Table = ({tableName, span, setTeams, teams, editTeam, setEditTeam, editFormData, setEditFormData, db}) => {
    const teams2 = [...teams].sort((a, b) => {
        if (a.wins === b.wins) {
            return a.losses - b.losses;
        }
        else return b.wins - a.wins;
    });

    const handleEditClick = (event, team) => {
        event.preventDefault();
        setEditTeam(team.name);

        const formValues = {
            name: team.name,
            wins: team.wins,
            losses: team.losses
        }
        setEditFormData(formValues);
    }

    const handleEditFormSubmit = async (event) => {
        event.preventDefault();

        const newTeams = [...teams];
        const index = teams.findIndex((team) => team.name === editTeam);

        const teamQuery = query(collection(db, "Teams"), where("name", "==", newTeams[index].name));
        const teamRef = await getDocs(teamQuery);

        await updateDoc(teamRef.docs[0].ref, {
            wins: parseInt(editFormData.wins),
            losses: parseInt(editFormData.losses)
        });

        const editedTeam = {
            name: newTeams[index].name,
            wins: parseInt(editFormData.wins),
            losses: parseInt(editFormData.losses)
        }

        newTeams[index] = editedTeam;
        setTeams(newTeams);
        setEditTeam(null);
    }

    const handleCancelClick = () => {
        setEditTeam(null);
    };

    // const handleDeleteClick = async (teamName) => {
    //     const newTeams = [...teams];
    //     const index = teams.findIndex((team) => team.name === teamName);

    //     const teamQuery = query(collection(db, "Teams"), where("name", "==", newTeams[index].name));
    //     const teamRef = await getDocs(teamQuery);

    //     await deleteDoc(teamRef.docs[0].ref);

    //     newTeams.splice(index, 1);
    //     setTeams(newTeams);
    // }
    
    return (
    <div className="table">
        <form onSubmit={handleEditFormSubmit}>
            <table className="content-table">
                <thead>
                    <tr>
                        <th colSpan={span}>{tableName}</th>
                    </tr>
                    <tr>
                        <th>Rank</th>
                        <th>Team</th>
                        <th>Games Played</th>
                        <th>Wins</th>
                        <th>Losses</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {teams2.map((team, counter) => (
                        <Fragment key={team.name}>
                            {editTeam === team.name ? (
                                <EditableRow
                                    team={team}
                                    editFormData={editFormData}
                                    setEditFormData={setEditFormData}
                                    handleCancelClick={handleCancelClick}
                                />
                            ) : (
                                <ReadOnlyRow
                                    team={team}
                                    counter={counter}
                                    handleEditClick={handleEditClick}
                                    // handleDeleteClick={handleDeleteClick}
                                />
                            )}
                        </Fragment>
                    ))}
                </tbody>
            </table>
        </form>
    </div>
    )
}


export default Table;