import React, { Fragment } from 'react'
import { Link } from 'react-router-dom';

const UpcomingWeek = ({tableName, span, schedule}) => {

    function dateDiffInDays(a) {
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        // Discard the time and time-zone information.
        const dateA = new Date(a);
        const dateB = new Date();
        const utc1 = Date.UTC(dateA.getFullYear(), dateA.getMonth(), dateA.getDate());
        const utc2 = Date.UTC(dateB.getFullYear(), dateB.getMonth(), dateB.getDate());
      
        return Math.floor((utc1 - utc2) / _MS_PER_DAY);
    }

    const upcoming = [];
    var counter = 0;
    schedule.every(game => {
        if(game.Week.includes("Week") && !game.Week.includes("Game") && !game.Week.includes("BYE")) {
            if(counter === 1) return false;
            const diff = dateDiffInDays(game["Home Team"]);
            if(diff >= 0) {
                counter++;
                upcoming.push(game);
            }
        }
        else if(counter === 1) upcoming.push(game);
        return true;
    });

    if(upcoming.length === 0) upcoming.push({
        "Week": "",
        "Home Team": "",
        "Away Team": "No Upcoming Games",
        "Time": "",
        "Score": ""
    })

    return (
        <div className="table">
            <table className="content-table">
                <thead>
                    <tr>
                        <th colSpan={span}>{tableName}</th>
                    </tr>
                    <tr>
                        <th>Week</th>
                        <th>Home Team</th>
                        <th>Away Team</th>
                        <th>Time</th>
                        <th>Score</th>
                    </tr>
                </thead>
                <tbody>
                {upcoming.map((game) => (
                     <Fragment key={game.Week + game.Time}>
                    {game.Week.includes("Week") && !game.Week.includes("Game") && !game["Week"].includes("BYE") ? (
                         <tr className='week'>
                             <td>{game.Week.includes("x") ? game.Week.replaceAll("x", "") : game.Week}</td>
                             <td colSpan={span-1}>{game["Home Team"]}</td>
                         </tr>
                     ) : (game["Week"].includes("BYE") && game["Home Team"].length > 0 ? (
                        <tr>
                            <td>{game.Week.split(/Week x*\d+ x*/)[1]}</td>
                            <td colSpan={2}>{game["Home Team"]}</td>
                            <td>{game["Away Team"]}</td>
                            <td></td>
                        </tr>
                    ) : (game["Week"].includes("BYE") && game["Home Team"].length === 0 ? (
                        <tr>
                            <td colSpan={span}>{game.Week.split(/Week x*\d+ x*/)[1]}</td>
                        </tr>
                        ) : (
                         <tr>
                             <td><Link to={!window.location.pathname.includes('admin') ? "/Box Score/" + game.Week : "/admin/Box Score/" + game.Week} className="teamlink">{game.Week.split(/Week x*\d+ /)[1]}</Link></td>
                             <td>{game["Home Team"]}</td>
                             <td>{game["Away Team"]}</td>
                             <td>{game.Time}</td>
                             <td>{game.Score}</td>
                         </tr>
                     )))}
                 </Fragment>
                ))}
                </tbody>
            </table>
        </div>
    )
}

export default UpcomingWeek