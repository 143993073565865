import React, { Fragment } from 'react'
import ReadOnlySchedule from './ReadOnlySchedule';
import EditableSchedule from './EditableSchedule';
import { updateDoc, collection, where, query, getDocs } from "firebase/firestore";

const Schedule = ({tableName, span, schedule, setSchedule, editSchedule, setEditSchedule, editScoreData, setEditScoreData, db}) => {

    const handleEditClick = (event, game) => {
        event.preventDefault();
        setEditSchedule(game["Home Team"] + game["Away Team"] + game["Score"]);

        const formValues = {
            Week: game["Week"],
            "Home Team": game["Home Team"],
            "Away Team": game["Away Team"],
            Time: game["Time"],
            Score: game["Score"]
        }
        setEditScoreData(formValues);
    }

    const handleEditScoreSubmit = async (event) => {
        event.preventDefault();

        const newSchedule = [...schedule];
        const index = schedule.findIndex((game) => (game["Home Team"] + game["Away Team"] + game["Score"]) === editSchedule);

        const schedQuery = query(collection(db, "Schedule"), where("Home Team", "==", newSchedule[index]["Home Team"]), 
        where("Away Team", "==", newSchedule[index]["Away Team"]), where("Score", "==", newSchedule[index]["Score"]));
        const schedRef = await getDocs(schedQuery);

        await updateDoc(schedRef.docs[0].ref, {
            Score: editScoreData.Score
        });

        const editedSchedule = {
            Week: newSchedule[index].Week,
            "Home Team": newSchedule[index]["Home Team"],
            "Away Team": newSchedule[index]["Away Team"],
            Time: newSchedule[index]["Time"],
            Score: editScoreData.Score
        }

        newSchedule[index] = editedSchedule;
        setSchedule(newSchedule);
        setEditSchedule(null);
    }

    const handleCancelClick = () => {
        setEditSchedule(null);
    };

    return (
        <div className="table center">
        <form onSubmit={handleEditScoreSubmit}>
            <table className="content-table">
                <thead>
                    <tr>
                        <th colSpan={span}>{tableName}</th>
                    </tr>
                    <tr>
                        <th>Week</th>
                        <th>Home Team</th>
                        <th>Away Team</th>
                        <th>Time</th>
                        <th>Score</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                {schedule.map((game) => (
                    <Fragment key={game["Week"] + game["Away Team"]}>
                        {editSchedule === game["Home Team"] + game["Away Team"] + game["Score"] ? (
                            <EditableSchedule
                                game={game}
                                editScoreData={editScoreData}
                                setEditScoreData={setEditScoreData}
                                handleCancelClick={handleCancelClick}
                            />
                        ) : (
                            <ReadOnlySchedule
                                game={game}
                                handleEditClick={handleEditClick}
                                span={span}
                            />
                        )}
                    </Fragment>
                ))}
                </tbody>
            </table>
        </form>
        </div>
    )
}

export default Schedule